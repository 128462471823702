import { Event } from 'client/types/event';
import { List } from 'client/types/common';
import * as types from './types';

export function fetchFinishedEventsRequest(pageNumber?: number): types.FetchFinishedEventsRequest {
    return { type: types.FETCH_FINISHED_EVENTS_REQUEST, payload: pageNumber };
}

export function fetchUpcomingEventsRequest(pageNumber?: number): types.FetchUpcomingEventsRequest {
    return { type: types.FETCH_UPCOMING_EVENTS_REQUEST, payload: pageNumber };
}

export function fetchFinishedEventsSuccess(data: List<Event>): types.FetchFinishedEventsSuccess {
    return { type: types.FETCH_FINISHED_EVENTS_SUCCESS, payload: data };
}

export function fetchUpcomingEventsSuccess(data: List<Event>): types.FetchUpcomingEventsSuccess {
    return { type: types.FETCH_UPCOMING_EVENTS_SUCCESS, payload: data };
}

export function fetchEventsError(error: Error): types.FetchEventsError {
    return { type: types.FETCH_EVENTS_ERROR, payload: error };
}
