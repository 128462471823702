import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import b from 'b_';

import { AppDataProps, withAppData } from 'client/contexts/appData';
import { getQueryValue } from 'client/components/filters/filters-query';
import ApiRequest from 'client/helpers/apiRequest';

const class_ = b.with('page');

interface State {
    isLoading: boolean;
    isConfirmed?: boolean;
}

type Props = RouteComponentProps & AppDataProps;

class ConfirmationPage extends Component<Props, State> {
    state: State = {
        isLoading: true,
    };

    componentDidMount() {
        const { location } = this.props;
        const query = getQueryValue(location.search);

        ApiRequest.get(`/api/confirmation/${query.token}`).then(
            () => this.setState({ isConfirmed: true, isLoading: false }),
            () => this.setState({ isConfirmed: false, isLoading: false }));
    }

    render() {
        const { i18n } = this.props.appData;
        const { isLoading, isConfirmed } = this.state;
        const message = i18n(isConfirmed ?
            'emailIsConfirmed' :
            'emailIsConfirmedAlready', 'confirmation');

        return (
            <div className={class_({ type: 'home' })}>
                <h1>{i18n('title', 'confirmation')}</h1>
                <div>{isLoading ? i18n('loading', 'confirmation') : message}</div>
            </div>
        );
    }
}

export default withAppData(ConfirmationPage);
