import { Homepage } from 'client/types/homepage';
import * as types from './types';

export function fetchHomepageRequest(): types.FetchHomepageRequest {
    return { type: types.FETCH_HOMEPAGE_REQUEST };
}

export function fetchHomepageSuccess(data: Homepage): types.FetchHomepageSuccess {
    return { type: types.FETCH_HOMEPAGE_SUCCESS, payload: data };
}

export function fetchHomepageError(error: Error): types.FetchHomepageError {
    return { type: types.FETCH_HOMEPAGE_ERROR, payload: error };
}
