import { withAppData } from '../../contexts/appData';
import { FetchDataComponent, ServerFetchDataProps } from '../../types/common';
import { getFiltersFromQuery, getQueryValue } from '../../components/filters/filters-query';
import { fetchHomepage } from '../../store/homepage/thunks';
import { fetchFinishedEvents, fetchUpcomingEvents } from '../../store/event/thunks';
import { EVENTS_COUNT_WITHOUT_PAGES } from '../../store/event/constants';
import HomePage from './home';

const component = withAppData(HomePage) as FetchDataComponent;

component.serverFetchData = ({ dispatch, location, external }: ServerFetchDataProps<null>) => {
    const query = getQueryValue(location.search);
    const { filters, speakerId } = getFiltersFromQuery(query.q as string);

    return Promise.all([
        dispatch(fetchHomepage({ external, speakerId })),
        dispatch(fetchFinishedEvents({ filters, speakerId, external })),
        dispatch(fetchUpcomingEvents({ filters, speakerId, external, pageSize: EVENTS_COUNT_WITHOUT_PAGES })),
    ]);
};

export default component;
