import React, { StatelessComponent } from 'react';
import b from 'b_';

import './rect.css';

const class_ = b.with('rect');

interface Props {
    type?: string;
    width?: number | string;
    height?: number | string;
    className?: string;
}

const Rect: StatelessComponent<Props> = ({ width, height, type = 'default', className }) => {
    const defaultClass = class_({ type });
    const classes = className ? `${defaultClass} ${className}` : defaultClass;

    return (
        <div style={{ width, height }} className={classes} />
    );
};

export default Rect;
