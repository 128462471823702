import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';

import homepage, { HomepageState } from './homepage/reducer';
import event, { EventState } from './event/reducer';

export interface State {
    readonly homepage: HomepageState;
    readonly event: EventState;
    readonly router: RouterState;
}

export default (history: any) => combineReducers<State>({
    homepage,
    event,
    router: connectRouter(history),
});
