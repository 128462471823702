import React, { Component } from 'react';
import { Spin } from 'lego-on-react';
import b from 'b_';

import { AppDataProps, withAppData } from 'client/contexts/appData';
import { Event } from 'client/types/event';
import { List } from 'client/types/common';
import EventCard from 'client/components/event-card';
import EventsStub from './events.stub';

import './events.css';

const class_ = b.with('events');

type Props = AppDataProps & {
    noEventsText: string;
    isLoading?: boolean;
    onClickToShowMore: () => void;
    data: List<Event>;
}

class Events extends Component<Props> {
    render() {
        const { noEventsText, isLoading, data: { meta, rows } } = this.props;
        const { pageNumber = 1, pageSize = 8 } = meta;

        // Если грузим не первую страницу, то загруженные меропрития не мокаем
        if (isLoading && pageNumber === 1) {
            return <EventsStub limit={pageNumber! * pageSize!} />;
        }

        if (!rows.length) {
            return (
                <div className={class_()}>
                    <div
                        className={class_('container')}
                        dangerouslySetInnerHTML={{ __html: noEventsText }}
                    />
                </div>
            );
        }

        return (
            <div className={class_()}>
                <div className={class_('container')}>
                    {rows.map(this.renderEvent)}
                    <div className={class_('fake-space-item')} />
                    <div className={class_('fake-space-item')} />
                    <div className={class_('fake-space-item')} />
                </div>
                {this.renderHasMore()}
            </div>
        );
    }

    private renderHasMore = () => {
        const { isLoading, data: { meta, rows }, appData: { i18n } } = this.props;
        const { totalSize } = meta;

        if (totalSize && totalSize <= rows.length) {
            return;
        }

        return (
            <div className={class_('more-container')}>
                <Spin cls={class_('spin')} size={'m'} progress={isLoading} />
                {!isLoading &&
                    <a
                        href="#"
                        className={class_('more')}
                        onClick={this.onClickToShowMore}
                    >
                        {i18n('showmore')}
                    </a>}
            </div>
        );
    };

    private renderEvent = (event: Event) => (
        <EventCard
            key={event.id}
            data={event}
        />
    );

    private onClickToShowMore = (e: React.MouseEvent) => {
        e.preventDefault();

        this.props.onClickToShowMore();
    };
}

export default withAppData(Events);
