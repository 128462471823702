import React, { Component } from 'react';
import { debounce } from 'lodash';
import b from 'b_';

import Events from 'client/components/events';
import Filters from 'client/components/filters';
import {
    FilterQueryParams,
    getFilters,
    getFiltersFromQuery,
} from 'client/components/filters/filters-query';
import { Homepage } from 'client/types/homepage';
import { List } from 'client/types/common';
import { Event } from 'client/types/event';
import { ApiFilters } from 'client/types/filters';
import { AppDataProps, withAppData } from 'client/contexts/appData';
import { FetchEvents } from 'client/store/event/thunks';
import { DEFAULT_PAGE_SIZE, EVENTS_COUNT_WITHOUT_PAGES } from 'client/store/event/constants';

import './home-content.css';

const class_ = b.with('home-content');

export interface DispatchProps {
    fetchHomepage(params?: { external?: boolean, speakerId?: string }): any;
    fetchFinishedEvents: FetchEvents
    fetchUpcomingEvents: FetchEvents;
}

export interface StateProps {
    data: Homepage;
    isLoading: boolean;
    isFinishedEventsLoading: boolean;
    isUpcomingEventsLoading: boolean;
    finishedEvents: List<Event>;
    upcomingEvents: List<Event>;
}

type Props = StateProps & DispatchProps & AppDataProps & {
    q: string;
    onChangeUrlQueryParams: (params: object) => void;
};

interface OwnState {
    initiated: boolean;
    pageNumberFinished: number;
    pageNumberUpcoming: number;
    filters?: ApiFilters;
}

class HomeContent extends Component<Props, OwnState> {
    static getDerivedStateFromProps(nextProps: Props, prevState: OwnState) {
        const { filters } = getFiltersFromQuery(nextProps.q);

        if (prevState.initiated) {
            return null;
        }

        return {
            filters,
            pageNumberFinished: 1,
            pageNumberUpcoming: 1,
            initiated: true,
        };
    }

    state: OwnState = {
        initiated: false,
        pageNumberFinished: 1,
        pageNumberUpcoming: 1,
    };

    componentDidMount() {
        const { fetchHomepage, fetchFinishedEvents, fetchUpcomingEvents, data: { filters }, q } = this.props;

        if (!Object.keys(filters).length) {
            fetchHomepage();
            fetchFinishedEvents({ filters: getFiltersFromQuery(q) });
            fetchUpcomingEvents({ filters: getFiltersFromQuery(q), pageSize: EVENTS_COUNT_WITHOUT_PAGES });
        }
    }

    render() {
        const {
            isLoading,
            isFinishedEventsLoading,
            isUpcomingEventsLoading,
            data: { filters },
            finishedEvents,
            upcomingEvents,
            q,
            appData: { i18n },
        } = this.props;

        const filtersProps = getFiltersFromQuery(q);

        return (
            <>
                <Filters
                    data={filters}
                    isLoading={isLoading}
                    onChangeFilters={debounce(this.onChangeFilters, 200)}
                    {...filtersProps}
                />
                {!upcomingEvents.rows.length && !finishedEvents.rows.length ?
                    <div className={class_()}>
                        <div
                            className={class_('container')}
                            dangerouslySetInnerHTML={{ __html: i18n('noevents') }}
                        />
                    </div> :
                    <>
                        <h2 className={class_('title')}>{ i18n('upcomingEvents') }</h2>
                        <Events
                            noEventsText={i18n('noUpcomingEvents')}
                            data={upcomingEvents}
                            isLoading={isUpcomingEventsLoading}
                            onClickToShowMore={this.onClickToShowMoreUpcoming}
                        />
                        <h2 className={class_('title')}>{ i18n('finishedEvents') }</h2>
                        <Events
                            noEventsText={i18n('noFinishedEvents')}
                            data={finishedEvents}
                            isLoading={isFinishedEventsLoading}
                            onClickToShowMore={this.onClickToShowMoreFinished}
                        />
                    </>
                }
            </>
        );
    }

    onLoadUpcomingEvents = () => {
        const { fetchUpcomingEvents, q } = this.props;
        const { pageNumberUpcoming, filters = {} } = this.state;
        const { speakerId } = getFiltersFromQuery(q);

        const pageSize = pageNumberUpcoming === 1 ?
            EVENTS_COUNT_WITHOUT_PAGES :
            DEFAULT_PAGE_SIZE;

        fetchUpcomingEvents({ filters, pageNumber: pageNumberUpcoming, speakerId, pageSize });
    };

    onLoadFinishedEvents = () => {
        const { fetchFinishedEvents, q } = this.props;
        const { pageNumberFinished, filters = {} } = this.state;
        const { speakerId } = getFiltersFromQuery(q);

        fetchFinishedEvents({ filters, pageNumber: pageNumberFinished, speakerId });
    };

    onClickToShowMoreFinished = () => {
        this.setState({ pageNumberFinished: this.state.pageNumberFinished + 1 }, this.onLoadFinishedEvents);
    };

    onClickToShowMoreUpcoming = () => {
        this.setState({ pageNumberUpcoming: this.state.pageNumberUpcoming + 1 }, this.onLoadUpcomingEvents);
    };

    onChangeFilters = (params: FilterQueryParams) => {
        const filters = getFilters(params);

        this.props.onChangeUrlQueryParams({ q: JSON.stringify(params) });

        this.setState({ filters, pageNumberFinished: 1, pageNumberUpcoming: 1 }, () => {
            this.onLoadFinishedEvents();
            this.onLoadUpcomingEvents();
        });
    };
}

export default withAppData(HomeContent);
