import { connect } from 'react-redux';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';

import { State } from 'client/store';
import { getHomepage, isLoading } from 'client/store/homepage/selectors';
import {
    getFinishedEvents,
    getUpcomingEvents,
    isFinishedLoading,
    isUpcomingLoading,
} from 'client/store/event/selectors';
import { fetchHomepage } from 'client/store/homepage/thunks';
import { fetchFinishedEvents, fetchUpcomingEvents } from 'client/store/event/thunks';
import HomeContent, { DispatchProps, StateProps } from './home-content';

const urlPropsQueryConfig = {
    q: { type: UrlQueryParamTypes.string },
};

const mapStateToProps = (state: State): StateProps => ({
    data: getHomepage(state),
    isLoading: isLoading(state),
    isFinishedEventsLoading: isFinishedLoading(state),
    isUpcomingEventsLoading: isUpcomingLoading(state),
    finishedEvents: getFinishedEvents(state),
    upcomingEvents: getUpcomingEvents(state),
});

const mapDispatchToProps = {
    fetchHomepage,
    fetchFinishedEvents,
    fetchUpcomingEvents,
};

export default connect<StateProps, DispatchProps>(
    mapStateToProps,
    mapDispatchToProps
)(addUrlProps({ urlPropsQueryConfig })(HomeContent));
