import React, { Component } from 'react';
import b from 'b_';
import { User, UserAccount } from 'lego-on-react';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { AppDataProps, withAppData } from 'client/contexts/appData';
import './account.css';

interface AccountData {
    uid: string;
    displayName: {
        // eslint-disable-next-line camelcase
        default_avatar?: string;
        name: string;
        social?: string;
    };
    defaultEmail: any;
}

type Props = RouteComponentProps & AppDataProps;

interface State {
    accounts: AccountData[];
    accountsLoaded: boolean;
}

const class_ = b.with('account');

const LegoUser = User as any;
const LegoUserAccount = UserAccount as any;

class Account extends Component<Props, State> {
    state = {
        accounts: [],
        accountsLoaded: false,
    };

    componentDidMount() {
        this.loadAccounts();
    }

    loadAccounts() {
        const { blackbox, yandexuid } = this.props.appData;

        fetch(`${blackbox.passHost}/accounts?yu=${yandexuid}`, {
            credentials: 'include',
        })
            .then(res => res.json())
            .then((data: any) => {
                const accounts = data.accounts || [];

                this.setState({
                    accounts: accounts.filter((account: AccountData) => account.uid !== data.default_uid),
                });
            })
            .catch((err: Error) => console.error(err))
            .finally(() => this.setState({ accountsLoaded: true }));
    }

    renderAccounts(accounts: AccountData[]) {
        const { avatarHost } = this.props.appData.blackbox;

        if (!accounts || !accounts.length) {
            return;
        }

        return accounts.map(({ displayName, defaultEmail, uid }) => (
            <LegoUserAccount
                avatarHost={avatarHost}
                uid={uid}
                key={`user-account-${uid}`}
                pic
                avatarId={displayName.default_avatar}
                name={displayName.name}
                subname={defaultEmail || displayName.social}
                mix={{ block: 'user2', elem: 'account', js: { uid } }}
            />
        ));
    }

    render() {
        const { blackbox, host, tld, yandexuid, i18n } = this.props.appData;
        const { uid, displayName, avatarId, passportHost, avatarHost } = blackbox;
        const { accounts, accountsLoaded } = this.state;

        const retpath = `${host}${this.props.location.pathname}`;
        const authUrl = `${passportHost}/auth?retpath=${retpath}`;

        if (!blackbox.isValid) {
            return (
                <div className={class_()}>
                    <a className={class_('auth')} href={authUrl}>{ i18n('enter') }</a>
                </div>
            );
        }

        return (
            <div className={class_()}>
                <LegoUser
                    uid={uid}
                    yu={yandexuid}
                    name={displayName}
                    passportHost={passportHost}
                    avatarHost={avatarHost}
                    avatarId={avatarId}
                    contentRegion={tld}
                    userAccount={{ avatarHost }}
                    retpath={retpath}
                    addAccountHidden
                    hasAccentLetter={false}
                    fetchAccounts={!accountsLoaded}
                >
                    {this.renderAccounts(accounts)}
                </LegoUser>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withAppData
)(Account);
