import React, { FC } from 'react';
import Helmet from 'react-helmet';
import b from 'b_';
import HomeContent from './home-content';

import { AppDataProps } from 'client/contexts/appData';

const class_ = b.with('page');

const HomePage: FC<AppDataProps> = ({ appData: { i18n, favicon } }: AppDataProps) => (
    <div className={class_({ type: 'home' })}>
        <Helmet>
            <title>{i18n('meta.title')}</title>
            <link rel="shortcut icon" href={favicon} />
            <meta name="description" content={i18n('meta.description')} />
            <meta name="keywords" content={i18n('meta.keywords')} />
            <meta name="viewport" content="width=device-width, maximum-scale=1.0, minimal-ui" />
            <meta property="og:title" content={i18n('meta.ogtitle')} />
            <meta property="og:description" content={i18n('meta.ogdescription')} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={i18n('meta.ogimage')} />
            <meta property="og:url" content={i18n('meta.ogurl')} />
        </Helmet>
        <HomeContent />
    </div>
);

export default HomePage;
