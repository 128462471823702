import React from 'react';
import b from 'b_';
import Rect from 'client/components/rect';

import './event-card.css';

const class_ = b.with('event-card');

export default () => {
    return (
        <div className={class_({ type: 'stub' })}>
            <div className={class_('image')} />
            <div className={class_('container')}>
                <div className={class_('date')}>
                    <Rect width="50%" height={14} />
                </div>
                <div className={class_('title')}>
                    <Rect width="100%" height={24} type="black" />
                </div>
                <div className={class_('registration')} />
            </div>
        </div>
    );
};
