import { groupBy } from 'lodash';
import startOfMonth from 'date-fns/start_of_month';
import startOfDay from 'date-fns/start_of_day';
import endOfMonth from 'date-fns/end_of_month';
import endOfDay from 'date-fns/end_of_day';
import addWeeks from 'date-fns/add_weeks';

import { Hash } from 'client/types/common';
import { FiltersItemProps, ApiFilters } from 'client/types/filters';

/**
 * Хелпер для формирования json-объекта конструктора фильтров для
 * запроса мероприятий c API. На вход метода make передается массив
 * фильтров (с типом и значением в поле id), который группирует их
 * по типу и для каждого типа формирует json-объект.
 *
 * На внешнем уровне применяется условие and, в рамках одного типа - or,
 * т.е. если будут выбраны фильтры Москва ИЛИ Новосибирск, события будут
 * искаться в двух городах, а если Москва И 2019, то события будут искаться
 * только по Москве в 2019 году.
 */
class FilterBuilder {
    static make(filters: FiltersItemProps[]): ApiFilters {
        const groupedFilters: Hash<FiltersItemProps[]> = groupBy(filters, 'type');

        const result = Object.entries(groupedFilters).reduce((acc, [key, data]) => {
            const formattedFilters: ApiFilters[] = [];

            data.forEach(filterItem => {
                const filter = this.getFilterFormatter(key)(filterItem);

                formattedFilters.push(filter);
            });

            acc.push({ or: formattedFilters });

            return acc;
        }, [] as ApiFilters[]);

        return { and: result };
    }

    static getFilterFormatter(type: string): (data: FiltersItemProps) => ApiFilters {
        switch (type) {
        case 'title':
            return (data: FiltersItemProps) => ({ title: { cont: data.id } });
        case 'tag':
            return (data: FiltersItemProps) => ({ 'tags.id': data.id });
        case 'period':
            return this.getFilterPeriod;
        default:
            return (data: FiltersItemProps) => ({ [type]: data.id });
        }
    }

    // Для периода формируем промежуток с начала месяца (года) до его конца
    static getFilterPeriod(data: FiltersItemProps) {
        // Кастомный фильтр для двух недель
        if (data.id === '2weeks') {
            return {
                and: [
                    { startDate: { gt: startOfDay(new Date()) } },
                    { startDate: { lt: addWeeks(startOfDay(new Date()), 2) } },
                ],
            };
        }

        const [year, month] = data.id.split('-');

        const startPeriod = startOfDay(startOfMonth(new Date(Number(year), month ? Number(month) : 0)));
        const endPeriod = endOfDay(endOfMonth(new Date(Number(year), month ? Number(month) : 11)));

        return { and: [{ startDate: { gt: startPeriod } }, { startDate: { lt: endPeriod } }] };
    }
}

export default FilterBuilder;
