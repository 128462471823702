import React, { Component } from 'react';
import b from 'b_';

import { AppDataProps, withAppData } from 'client/contexts/appData';

import './footer.css';

const feedbackUrl = 'https://yandex.ru/support/events/index.html';

type Props = AppDataProps;

const class_ = b.with('footer');

class Footer extends Component<Props> {
    render() {
        const { cfg: { socialNetworks }, i18n } = this.props.appData;

        return (
            <div className={class_()}>
                <div className={class_('blocks')}>
                    <div
                        className={class_('blocks-item')}
                        dangerouslySetInnerHTML={{ __html: i18n('footer.feedback')
                            .replace('{{feedbackUrl}}', feedbackUrl) }}
                    />
                    <div className={class_('blocks-item')}>
                        {i18n('footer.share')}
                        <div className={class_('social')}>
                            {socialNetworks.map(({ type, url }) => (
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={type}
                                    href={url}
                                    className={class_('social-item', { type })}
                                />
                            ))}
                        </div>
                    </div>
                    <div
                        className={class_('blocks-item')}
                        dangerouslySetInnerHTML={{ __html: i18n('footer.video') }}
                    />
                </div>
            </div>
        );
    }
}

export default withAppData(Footer);
