import { Action } from 'redux';
import { Homepage } from 'client/types/homepage';

export const FETCH_HOMEPAGE_REQUEST = '@@homepage/FETCH_HOMEPAGE_REQUEST';
export const FETCH_HOMEPAGE_SUCCESS = '@@homepage/FETCH_HOMEPAGE_SUCCESS';
export const FETCH_HOMEPAGE_ERROR = '@@homepage/FETCH_HOMEPAGE_ERROR';

export interface FetchHomepageRequest extends Action {
    type: typeof FETCH_HOMEPAGE_REQUEST
}

export interface FetchHomepageSuccess extends Action {
    type: typeof FETCH_HOMEPAGE_SUCCESS
    payload: Homepage
}

export interface FetchHomepageError extends Action {
    type: typeof FETCH_HOMEPAGE_ERROR
    payload: Error
}

export type HomepageActionTypes =
    | FetchHomepageRequest
    | FetchHomepageSuccess
    | FetchHomepageError;
