import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import ApiRequest from 'client/helpers/apiRequest';
import { Homepage } from 'client/types/homepage';
import * as actions from './actions';

interface Params {
    external?: boolean;
    speakerId?: string;
}

export const fetchHomepage = ({ external, speakerId }: Params = {}):
    ThunkAction<Promise<any>, any, null, Action<string>> => dispatch => {
    dispatch(actions.fetchHomepageRequest());

    return ApiRequest.get<Homepage>('/api/homepage', { params: { speakerId }, external }).then(
        (response = { filters: {} }) => dispatch(actions.fetchHomepageSuccess(response)),
        error => dispatch(actions.fetchHomepageError(error))
    );
};
