import React, { Component, StatelessComponent } from 'react';
import { Link, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import b from 'b_';

import { AppDataProps, withAppData } from 'client/contexts/appData';

// Компонент для передачи статуса при серверном рендеринге
// Например, если страница не найдена, отдаем клиенту 404 ошибку
const Status: StatelessComponent<any> = ({ code, children }) => {
    const render = ({ staticContext }: any) => {
        if (staticContext) {
            staticContext.status = code;
        }

        return children;
    };

    return (
        <Route render={render} />
    );
};

const class_ = b.with('page');

class NotFoundPage extends Component<AppDataProps> {
    render() {
        const { i18n, favicon } = this.props.appData;

        return (
            <Status code={404}>
                <Helmet>
                    <title>{i18n('meta.title', 'notFound')}</title>
                    <link rel="shortcut icon" href={favicon} />
                    <meta name="description" content={i18n('meta.description')} />
                    <meta name="keywords" content={i18n('meta.keywords')} />
                </Helmet>
                <div className={class_({ type: 'not-found' })}>
                    <h1>{i18n('title', 'notFound')}</h1>
                    {i18n('message', 'notFound')} <Link to="/">{i18n('link', 'notFound')}</Link>
                </div>
            </Status>
        );
    }
}

export default withAppData(NotFoundPage);
