import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import b from 'b_';

import { AppDataProps, withAppData } from 'client/contexts/appData';
import { getQueryValue } from 'client/components/filters/filters-query';
import ApiRequest from 'client/helpers/apiRequest';

const class_ = b.with('page');

interface State {
    isLoading: boolean;
    isUnsubscribed?: boolean;
}

type Props = AppDataProps & RouteComponentProps;

class UnsubscriptionPage extends Component<Props, State> {
    state: State = {
        isLoading: true,
    };

    componentDidMount() {
        const { location } = this.props;
        const query = getQueryValue(location.search);

        ApiRequest.get(`/api/unsubscribe/${query.token}`).then(
            () => this.setState({ isUnsubscribed: true, isLoading: false }),
            () => this.setState({ isUnsubscribed: false, isLoading: false }));
    }

    render() {
        const { i18n } = this.props.appData;
        const { isLoading, isUnsubscribed } = this.state;
        const message = i18n(isUnsubscribed ? 'success' : 'failed', 'unsubscription');

        return (
            <div className={class_({ type: 'home' })}>
                <h1>{i18n('title', 'unsubscription')}</h1>
                <div>{isLoading ? i18n('loading') : message}</div>
            </div>
        );
    }
}

export default withAppData(UnsubscriptionPage);
