import axios, { AxiosRequestConfig } from 'axios';

const defaultOptions: object = {
    withCredentials: true,
};

interface Options {
    external?: boolean;
}

class ApiRequest {
    static async get<T = any>(url: string, options = {} as Options & AxiosRequestConfig): Promise<T> {
        let reqUrl = url;
        const { external } = options;

        if (external || (typeof window !== 'undefined' && window.location.hostname.endsWith('com'))) {
            reqUrl = reqUrl.replace('api', 'api/external');
        }

        const config = { ...defaultOptions, ...options };
        const response = await axios.get(reqUrl, config);

        return response.data;
    }
}

export default ApiRequest;
