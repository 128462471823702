import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import ApiRequest from 'client/helpers/apiRequest';
import { Event } from 'client/types/event';
import { List } from 'client/types/common';
import { ApiFilters } from 'client/types/filters';
import { EventsActionTypes } from './types';
import { DEFAULT_PAGE_SIZE } from './constants';
import * as actions from './actions';

export interface Params {
    filters?: ApiFilters;
    pageNumber?: number;
    pageSize?: number;
    speakerId?: string;
    external?: boolean;
    isFinished?: boolean;
    isUpcoming?: boolean;
}

export type FetchEvents = (
    params: Params,
    fetchventsRequest?: (pageNumber?: number) => EventsActionTypes,
    fetchEventsSuccess?: (data: List<Event>) => EventsActionTypes
    ) => ThunkAction<any, any, null, Action<string>>;

export const fetchEvents: FetchEvents = (params = {}, fetchventsRequest, fetchEventsSuccess) => dispatch => {
    const { pageNumber, pageSize = DEFAULT_PAGE_SIZE, filters, external, speakerId, isFinished, isUpcoming } = params;

    fetchventsRequest && dispatch(fetchventsRequest(pageNumber));

    const query = {
        pageNumber,
        pageSize,
        filters: JSON.stringify(filters),
        speakerId,
        isFinished,
        isUpcoming,
    };

    return ApiRequest.get<List<Event>>('/api/events', { params: query, external }).then(
        response => {
            fetchEventsSuccess && dispatch(fetchEventsSuccess(response));
        },
        error => {
            if (typeof window !== 'undefined') {
                window.Ya.Rum.logError(
                    {
                        message: error.message,
                        block: 'events',
                        method: 'fetchEvents',
                        level: window.Ya.Rum.ERROR_LEVEL.WARN,
                    },
                    error
                );
            }

            console.warn(error);

            return dispatch(actions.fetchEventsError(error));
        }
    );
};

export const fetchUpcomingEvents: FetchEvents = (params = {}) => {
    return fetchEvents(
        { ...params, isUpcoming: true },
        actions.fetchUpcomingEventsRequest,
        actions.fetchUpcomingEventsSuccess
    );
};

export const fetchFinishedEvents: FetchEvents = (params = {}) => {
    return fetchEvents(
        { ...params, isFinished: true },
        actions.fetchFinishedEventsRequest,
        actions.fetchFinishedEventsSuccess
    );
};
