import { State } from '../rootReducer';

export function getFinishedEvents(state: State) {
    return state.event.finishedData;
}

export function getUpcomingEvents(state: State) {
    return state.event.upcomingData;
}

export function isFinishedLoading(state: State) {
    return state.event.isFinishedLoading;
}

export function isUpcomingLoading(state: State) {
    return state.event.isUpcomingLoading;
}
