import { RouteProps } from 'react-router-dom';
import HomePage from 'client/pages/home';
import ConfirmationPage from 'client/pages/confirmation';
import UnsubscriptionPage from 'client/pages/unsubscription';
import NotFoundPage from 'client/pages/not-found';

const routes: RouteProps[] = [
    {
        path: '/',
        component: HomePage,
        exact: true,
    },
    {
        path: '/confirmation',
        component: ConfirmationPage,
        exact: true,
    },
    {
        path: '/unsubscribe',
        component: UnsubscriptionPage,
        exact: true,
    },
    {
        path: '*',
        component: NotFoundPage,
        exact: true,
    },
];

export default routes;
