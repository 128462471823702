import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import thunk from 'redux-thunk';
import createRootReducer from './rootReducer';

export { State } from './rootReducer';

export const isServer = !(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

export default function configureStore(initialState = {}, url = '/') {
    const history = isServer ?
        createMemoryHistory({ initialEntries: [url] }) :
        createBrowserHistory();

    const middlewares = [routerMiddleware(history), thunk];
    const store = createStore(
        createRootReducer(history),
        initialState,
        composeWithDevTools(applyMiddleware(...middlewares))
    );

    return { history, store };
}
