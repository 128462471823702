import React, { Component } from 'react';
import b from 'b_';
import { FiltersTab, FiltersItemProps } from 'client/types/filters';
import FiltersItem from 'client/components/filters-item';

interface Props {
    data: FiltersTab[];
    type: string;
    onClick: (filterProps: FiltersItemProps, type: string) => void;
    selectedFilters: FiltersItemProps[];
}

interface State {
    activeTabIdx: number;
}

const class_ = b.with('filters');

class FiltersList extends Component<Props, State> {
    state = {
        activeTabIdx: 0,
    };

    render() {
        /* eslint-disable react/jsx-no-bind */

        const { data = [] } = this.props;
        const { activeTabIdx } = this.state;
        const showTabs = data.length > 1;

        if (!data[activeTabIdx]) {
            return null;
        }

        return (
            <div className={class_('list-wrapper')}>
                {showTabs && (
                    <div className={class_('tabs-container')}>
                        <div className={class_('tabs-wrapper')}>
                            {Object.values(data).map(({ label }, idx) => (
                                <span
                                    key={idx}
                                    className={class_('tabs', { active: idx === activeTabIdx })}
                                    onClick={() => this.onChangeTab(idx)}
                                >
                                    {label}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
                <div className={class_('list-items-container')}>
                    <div className={class_('list-items')}>
                        {data[activeTabIdx].children!.map(filterProps => (
                            <FiltersItem
                                key={filterProps.id}
                                selected={this.isSelected(filterProps.id)}
                                onClick={() => this.onClick(filterProps)}
                                {...filterProps}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    private onChangeTab = (idx: number) => {
        this.setState({ activeTabIdx: idx });
    };

    private isSelected = (id: string) => {
        const { selectedFilters, type } = this.props;
        const selected = selectedFilters.filter(filter => filter.type === type && filter.id === id);

        return Boolean(selected.length);
    };

    private onClick = (filterProps: FiltersItemProps) => {
        const { onClick, type } = this.props;

        onClick(filterProps, type);
    }
}

export default FiltersList;
