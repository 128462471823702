export enum RegistrationStatus {
    FREE = 'free',
    OPENED = 'opened',
    OPENED_LATER = 'opened_later',
    CLOSED = 'closed'
}

export interface Event {
    id: number;
    slug: string;
    title: string;
    shortDescription: string;
    startDate: Date | null;
    endDate: Date | null;
    dateIsConfirmed: boolean;
    redirectUrl: string | null;
    registrationStartDate: Date | null;
    registrationEndDate: Date | null;
    registrationDateIsConfirmed: boolean;
    registrationStatus: RegistrationStatus;
    hasOnlineBroadcast: boolean;
    isBroadcastEnabled: boolean;
    city: string;
    timezone: string;
    isFinished: boolean;
    hasPage: boolean;
    image: string | null;
}
