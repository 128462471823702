import { Event } from 'client/types/event';
import { List } from 'client/types/common';
import { DEFAULT_PAGE_SIZE, EVENTS_COUNT_WITHOUT_PAGES } from './constants';
import * as types from './types';

export interface EventState {
    readonly finishedData: List<Event>;
    readonly upcomingData: List<Event>;
    readonly isFinishedLoading: boolean;
    readonly isUpcomingLoading: boolean;
    readonly error?: string;
}

const initialState: EventState = {
    finishedData: { rows: [], meta: {} },
    upcomingData: { rows: [], meta: {} },
    isFinishedLoading: false,
    isUpcomingLoading: false,
};

function fetchFinishedEvents(state: EventState, action: types.FetchFinishedEventsRequest) {
    return {
        ...state,
        finishedData: {
            ...state.finishedData,
            meta: {
                ...state.finishedData.meta,
                pageNumber: action.payload,
            },
        },
        isFinishedLoading: true,
        error: undefined,
    };
}

function fetchUpcomingEvents(state: EventState, action: types.FetchUpcomingEventsRequest) {
    return {
        ...state,
        upcomingData: {
            ...state.upcomingData,
            meta: {
                ...state.upcomingData.meta,
                pageNumber: action.payload,
            },
        },
        isUpcomingLoading: true,
        error: undefined,
    };
}

function fetchFinishedEventsSuccess(state: EventState, action: types.FetchFinishedEventsSuccess) {
    const { meta, rows: actualRows } = action.payload;

    // Если загружаем не первую страницу, то не удаляем предыдущие события
    const rows = meta.pageNumber === 1 ?
        actualRows :
        [...state.finishedData.rows, ...actualRows];

    return {
        ...state,
        finishedData: { meta, rows },
        isFinishedLoading: false,
        error: undefined,
    };
}

function fetchUpcomingEventsSuccess(state: EventState, action: types.FetchUpcomingEventsSuccess) {
    const { meta, rows: actualRows } = action.payload;

    const eventsCount = meta.totalSize && meta.totalSize > EVENTS_COUNT_WITHOUT_PAGES ?
        DEFAULT_PAGE_SIZE :
        actualRows.length;

    const rows = meta.pageNumber === 1 ?
        actualRows.slice(0, eventsCount) :
        [...state.upcomingData.rows, ...actualRows];

    return {
        ...state,
        upcomingData: { meta, rows },
        isUpcomingLoading: false,
        error: undefined,
    };
}

function fetchEventsError(state: EventState, action: types.FetchEventsError) {
    const { payload } = action;
    const errorMessage = payload.message;

    return {
        ...state,
        finishedData: initialState.finishedData,
        upcomingData: initialState.upcomingData,
        isFinishedLoading: false,
        isUpcomingLoading: false,
        error: errorMessage,
    };
}

export default function eventReducer(
    state = initialState,
    action: types.EventsActionTypes
): EventState {
    switch (action.type) {
    case types.FETCH_FINISHED_EVENTS_REQUEST:
        return fetchFinishedEvents(state, action);
    case types.FETCH_UPCOMING_EVENTS_REQUEST:
        return fetchUpcomingEvents(state, action);
    case types.FETCH_FINISHED_EVENTS_SUCCESS:
        return fetchFinishedEventsSuccess(state, action);
    case types.FETCH_UPCOMING_EVENTS_SUCCESS:
        return fetchUpcomingEventsSuccess(state, action);
    case types.FETCH_EVENTS_ERROR:
        return fetchEventsError(state, action);
    default:
        return state;
    }
}
