import React from 'react';
import b from 'b_';
import { FiltersItemThemes } from 'client/types/filters';
import FiltersItem from 'client/components/filters-item';

const class_ = b.with('filters');

export default () => {
    const items = Array(3).fill(0);

    return (
        <div className={class_()}>
            <div className={class_('container')}>
                {items.map((_, idx) => (
                    <FiltersItem
                        key={idx}
                        theme={FiltersItemThemes.main}
                        label="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        id={idx.toString()}
                    />
                ))}
            </div>
        </div>
    );
};
