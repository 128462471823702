import { Action } from 'redux';
import { Event } from 'client/types/event';
import { List } from 'client/types/common';

export const FETCH_FINISHED_EVENTS_REQUEST = '@@homepage/FETCH_FINISHED_EVENTS_REQUEST';
export const FETCH_UPCOMING_EVENTS_REQUEST = '@@homepage/FETCH_UPCOMING_EVENTS_REQUEST';
export const FETCH_FINISHED_EVENTS_SUCCESS = '@@homepage/FETCH_FINISHED_EVENTS_SUCCESS';
export const FETCH_UPCOMING_EVENTS_SUCCESS = '@@homepage/FETCH_UPCOMING_EVENTS_SUCCESS';
export const FETCH_EVENTS_ERROR = '@@homepage/FETCH_EVENTS_ERROR';

export interface FetchFinishedEventsRequest extends Action {
    type: typeof FETCH_FINISHED_EVENTS_REQUEST,
    payload?: number
}

export interface FetchUpcomingEventsRequest extends Action {
    type: typeof FETCH_UPCOMING_EVENTS_REQUEST,
    payload?: number
}

export interface FetchFinishedEventsSuccess extends Action {
    type: typeof FETCH_FINISHED_EVENTS_SUCCESS
    payload: List<Event>
}

export interface FetchUpcomingEventsSuccess extends Action {
    type: typeof FETCH_UPCOMING_EVENTS_SUCCESS
    payload: List<Event>
}

export interface FetchEventsError extends Action {
    type: typeof FETCH_EVENTS_ERROR
    payload: Error
}

export type EventsActionTypes =
    | FetchFinishedEventsRequest
    | FetchUpcomingEventsRequest
    | FetchFinishedEventsSuccess
    | FetchUpcomingEventsSuccess
    | FetchEventsError;
