import { Homepage } from 'client/types/homepage';
import * as types from './types';

export interface HomepageState {
    readonly data: Homepage;
    readonly isLoading: boolean;
    readonly error?: Error;
}

const initialState: HomepageState = {
    data: { filters: {} },
    isLoading: false,
};

function fetchHomepage(state: HomepageState) {
    return {
        ...state,
        isLoading: true,
        error: undefined,
    };
}

function fetchHomepageSuccess(state: HomepageState, action: types.FetchHomepageSuccess) {
    return {
        ...state,
        data: action.payload,
        isLoading: false,
        error: undefined,
    };
}

function fetchHomepageError(state: HomepageState, action: types.FetchHomepageError) {
    return {
        ...state,
        data: initialState.data,
        isLoading: false,
        error: action.payload,
    };
}

export default function homepageReducer(
    state = initialState,
    action: types.HomepageActionTypes
): HomepageState {
    switch (action.type) {
    case types.FETCH_HOMEPAGE_REQUEST:
        return fetchHomepage(state);
    case types.FETCH_HOMEPAGE_SUCCESS:
        return fetchHomepageSuccess(state, action);
    case types.FETCH_HOMEPAGE_ERROR:
        return fetchHomepageError(state, action);
    default:
        return state;
    }
}
