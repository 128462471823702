import 'babel-polyfill';

import React, { Component } from 'react';
import { hydrate } from 'react-dom';
import { hot } from 'react-hot-loader/root';
import { Provider as ReduxProvider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RouterToUrlQuery } from 'react-url-query';
import { ConnectedRouter } from 'connected-react-router';
import { YMInitializer } from 'react-yandex-metrika';

import routes from 'client/routes';
import configureStore, { State } from 'client/store';
import Header from 'client/components/header';
import Footer from 'client/components/footer';
import { AppData } from 'client/contexts/appData';

import { BundleData, BundleProps } from './types';

import '../styles/base.css';

class Bundle extends Component<BundleProps> {
    static renderRoutes() {
        return routes.map((route, idx) => <Route key={idx} {...route} />);
    }

    render() {
        const { data } = this.props;
        const { metrikaCounters } = data;

        return (
            <RouterToUrlQuery>
                <AppData.Provider value={data}>
                    <Header />
                    <div className="page-container">
                        <Switch>{Bundle.renderRoutes()}</Switch>
                    </div>

                    {Boolean(metrikaCounters.length) && (
                        <YMInitializer
                            accounts={metrikaCounters}
                            options={{ webvisor: true }}
                            version="2"
                        />
                    )}

                    <Footer />
                </AppData.Provider>
            </RouterToUrlQuery>
        );
    }
}

export const DesktopBundle = hot(Bundle);

export default (data: BundleData, state: State) => {
    const { store, history } = configureStore(state);

    hydrate(
        <ReduxProvider store={store}>
            <ConnectedRouter history={history}>
                <DesktopBundle data={data} />
            </ConnectedRouter>
        </ReduxProvider>,
        document.getElementById('mount')
    );
};
