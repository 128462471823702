import React, { Component, ComponentType, createContext } from 'react';
import { Omit } from 'react-router';
import { BundleData } from 'client/bundles/types';

export const AppData = createContext<BundleData>({} as any);

export interface AppDataProps {
    appData: BundleData;
}

export function withAppData<P extends AppDataProps>(ComposedComponent: ComponentType<P>) {
    return class extends Component<Omit<P, keyof AppDataProps>> {
        i18n = (data: BundleData) => {
            const { tanker } = data;

            return (key: string, keyset: string = 'homepage') => {
                const text = tanker[keyset][key];

                if (!text) {
                    return '';
                }

                return text.form;
            };
        }

        public render() {
            return (
                <AppData.Consumer>
                    {data => {
                        data.i18n = this.i18n(data);

                        return <ComposedComponent appData={data} {...this.props as any} />;
                    }
                    }
                </AppData.Consumer>
            );
        }
    };
}
