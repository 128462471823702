import format from 'date-fns/format';
import localeRu from 'date-fns/locale/ru';
import localeEn from 'date-fns/locale/en';

export function formatDate(
    date: Date | string,
    dateFormat: string = 'Do [de] MMMM YYYY',
    tld: string = 'ru'
): string {
    return format(date, dateFormat, { locale: tld === 'com' ? localeEn : localeRu });
}
