import React, { Component } from 'react';
import b from 'b_';

import { AppDataProps, withAppData } from 'client/contexts/appData';
import Account from 'client/components/account';
import { HeadMenuItem } from 'client/bundles/types';

import './header.css';

type Props = AppDataProps;
interface State { isMenuOpened: boolean; }

const class_ = b.with('header');

class Header extends Component<Props, State> {
    additionMenuRef = React.createRef<HTMLDivElement>();
    hamburgerBtnRef = React.createRef<HTMLDivElement>();

    state = {
        isMenuOpened: false,
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    onHamburgerClick = () => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened });
    };

    handleClickOutside = (event: Event) => {
        const { current: menu } = this.additionMenuRef;
        const { current: hamburger } = this.hamburgerBtnRef;

        const clickOutsideMenu = menu && !menu.contains(event.target as Node);
        const clickOutsideHamburger = hamburger && !hamburger.contains(event.target as Node);

        if (clickOutsideMenu && clickOutsideHamburger) {
            this.setState({ isMenuOpened: false });
        }
    };

    renderMenu(menu: HeadMenuItem[]) {
        const { i18n } = this.props.appData;
        const externalProps = { target: '_blank', rel: 'noopener noreferrer external' };

        return menu.map(({ url, title, active, internal }) => (
            <a
                key={title}
                href={url}
                className={active ? class_('item', { active: true }) : class_('item')}
                {...(internal ? {} : externalProps)}
            >
                {i18n(`headMenu.${title}`)}
            </a>
        ));
    }

    renderLogo() {
        const { i18n, tld } = this.props.appData;

        return (
            <div className={class_('logo')}>
                <a href={tld === 'ru' ? 'https://ya.ru' : `https://yandex.${tld}`}>
                    <img
                        alt={i18n('logoalt')}
                        src={`https://yastatic.net/q/logoaas/v2/${i18n('logoalt')}.svg?color=fff&size=30`}
                    />
                </a>
            </div>
        );
    }

    render() {
        const { main, addition } = this.props.appData.cfg.headMenu;
        const { isMenuOpened } = this.state;

        return (
            <div className={class_({ active: isMenuOpened })}>
                <div className={class_('cover')} />
                <div className={class_('wrapper')}>
                    {this.renderLogo()}
                    <div
                        ref={this.hamburgerBtnRef}
                        className={class_('hamburger')}
                        onClick={this.onHamburgerClick}
                    />
                    <div className={class_('user')}>
                        <Account />
                    </div>
                    <div className={class_('menu')}>
                        {this.renderMenu(main)}
                    </div>
                    {isMenuOpened &&
                    <div ref={this.additionMenuRef} className={class_('menu', { addition: true })}>
                        {this.renderMenu(addition)}
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default withAppData(Header);
