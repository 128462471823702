import { Hash } from './common';

export enum FiltersItemThemes {
    simple = 'simple',
    main = 'main'
}

export interface FiltersItemProps {
    id: string;
    label?: string;
    fullLabel?: string;
    withFullLabel?: boolean; // Если true, будет отображаться значение из fullLabel
    type?: string; // Тип элемента фильтра (период, тег или город)
    selected?: boolean; // Выделенный элемент или нет
    mods?: Hash; // Модификаторы для элемента фильтра
}

export interface FiltersTab {
    label: string;
    children?: FiltersItemProps[];
    disabled?: boolean;
}

export type FiltersData = Hash<FiltersTab[]>;

interface ApiFiltersCondition {
    gt?: string | Date;
    lt?: string | Date;
    cont?: string;
}

export interface ApiFilters {
    or?: ApiFilters[];
    and?: ApiFilters[];

    [key: string]: ApiFilters[] | ApiFiltersCondition | string | number | undefined;
}
