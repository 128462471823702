import React, { Component } from 'react';
import b from 'b_';

import { AppDataProps, withAppData } from 'client/contexts/appData';
import { FiltersItemProps, FiltersItemThemes } from 'client/types/filters';

import './filters-item.css';

export type Props = FiltersItemProps & AppDataProps & {
    theme?: FiltersItemThemes;
    onClick?: (id?: string, type?: string) => void;
}

const class_ = b.with('filters-item');

class FiltersItem extends Component<Props> {
    static defaultProps = {
        theme: FiltersItemThemes.simple,
        // eslint-disable-next-line no-empty-function
        onClick: () => {},
    };

    render() {
        const {
            theme,
            selected,
            label,
            fullLabel,
            withFullLabel,
            mods = {},
            appData: { i18n } } = this.props;

        const labelName = (withFullLabel && fullLabel) || label;
        const text = i18n(`filterItem.label.${labelName}`) || labelName;

        return (
            <div
                onClick={this.onClick}
                className={class_({ theme, selected, ...mods })}
            >
                {text}
            </div>
        );
    }

    onClick = () => {
        const { id, type, onClick } = this.props;

        onClick!(id, type);
    };
}

export default withAppData(FiltersItem);
