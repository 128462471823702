import { FiltersItemProps } from 'client/types/filters';
import FilterBuilder from 'client/helpers/filterBuilder';
import { Hash } from 'client/types/common';

export interface FilterQueryParams {
    selectedMainFilterId?: string | null;
    selectedFilters?: FiltersItemProps[];
    eventName?: string | null;
}

export function getQueryValue(search = ''): Hash<string> {
    try {
        return search
            .replace(/^\?/, '')
            .split('&')
            .reduce((acc: Hash<string>, item: string) => {
                const [key, value] = item.split('=');

                acc[decodeURIComponent(key)] = decodeURIComponent(value) || '';

                return acc;
            }, {});
    } catch (err) {
        return {};
    }
}

export function getFilters(params: FilterQueryParams) {
    try {
        const { eventName, selectedFilters = [] } = params;
        const filtersList = [...selectedFilters];

        if (eventName) {
            filtersList.push({ id: eventName, type: 'title' });
        }

        return FilterBuilder.make(filtersList);
    } catch (err) {
        return {};
    }
}

export function getFiltersFromQuery(queryFilters: string) {
    try {
        const params = JSON.parse(queryFilters);

        return { filters: getFilters(params), ...params };
    } catch (err) {
        return { filters: {} };
    }
}
