import React, { Component } from 'react';
import b from 'b_';
import { isTomorrow, isToday, getYear } from 'date-fns';

import { AppDataProps, withAppData } from 'client/contexts/appData';
import { formatDate } from 'client/helpers/formatDate';
import { Event, RegistrationStatus } from 'client/types/event';

import './event-card.css';

const class_ = b.with('event-card');

const statusesToShow = [RegistrationStatus.CLOSED, RegistrationStatus.OPENED];

type Props = AppDataProps & {
    data: Event;
}

class EventCard extends Component<Props> {
    // eslint-disable-next-line max-statements
    getDate(startDate: Date | null) {
        const { tld, i18n } = this.props.appData;

        if (!startDate) {
            return '';
        }

        // Удаляем таймзону и показываем время по местному времени,
        // Которое соответствует времени городу проведения события
        const dateWithoutTimezone = startDate
            .toString()
            .replace(/\+.*/, '');

        if (isToday(dateWithoutTimezone)) {
            return `${i18n('date.today')} ${formatDate(dateWithoutTimezone, 'H:mm')}`;
        }

        if (isTomorrow(dateWithoutTimezone)) {
            return `${i18n('date.tomorrow')} ${formatDate(dateWithoutTimezone, 'H:mm')}`;
        }

        if (getYear(startDate) !== getYear(Date.now())) {
            return formatDate(dateWithoutTimezone, 'dd, D MMMM YYYY', tld);
        }

        return formatDate(dateWithoutTimezone, 'dd, D MMMM', tld);
    }

    renderRegistration(status: RegistrationStatus, isFinished: boolean): React.ReactNode {
        const { i18n } = this.props.appData;
        const shouldShowStatus = statusesToShow.includes(status);

        return (shouldShowStatus && !isFinished ? (
            <div className={class_('registration', { status })}>
                {i18n(`registration.status.${shouldShowStatus ? status : ''}`)}
            </div>
        ) : null);
    }

    // eslint-disable-next-line complexity
    render() {
        const {
            id,
            slug,
            title,
            city,
            registrationStatus: status,
            startDate,
            isFinished,
            image,
            redirectUrl,
        } = this.props.data;
        const { i18n } = this.props.appData;

        const imageStyles = image ? { backgroundImage: `url(${image.replace(/orig$/, '286x137x2')})` } : {};
        const highlighted = isToday(startDate as Date) || isTomorrow(startDate as Date);
        const eventStartDate = startDate ? this.getDate(startDate) : i18n('date.isNotConfirmed');

        return (
            <div data-id={id} className={class_({ highlighted, finished: !highlighted && isFinished })}>
                <a href={redirectUrl || `/events/${slug}`} title={title}>
                    <div className={class_('image')} style={imageStyles} />
                </a>
                <div className={class_('container')}>
                    <div className={class_('date')}>
                        {[eventStartDate, city].filter(Boolean).join(', ')}
                    </div>
                    <a
                        href={redirectUrl || `/events/${slug}`}
                        className={class_('title')}
                        title={title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    {this.renderRegistration(status, isFinished)}
                </div>
            </div>
        );
    }
}

export default withAppData(EventCard);
