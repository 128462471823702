import React from 'react';
import { Icon as LegoIcon, IconProps } from 'lego-on-react';

import './icon.css';

const Icon = (props: IconProps) => (
    <LegoIcon {...props} />
);

export default Icon;
