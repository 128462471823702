import React from 'react';
import b from 'b_';

import EventCardStub from 'client/components/event-card/event-card.stub';

import './events.css';

const class_ = b.with('events');

interface Props {
    limit?: number;
}

export default (props: Props) => {
    const { limit = 8 } = props;
    const items = Array(limit).fill(0);

    return (
        <div className={class_()}>
            <div className={class_('container')}>
                {items.map((_, idx) => (
                    <EventCardStub key={idx} />
                ))}
                <div className={class_('fake-space-item')} />
                <div className={class_('fake-space-item')} />
                <div className={class_('fake-space-item')} />
            </div>
        </div>
    );
};
