import React, { Component } from 'react';
import b from 'b_';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import pick from 'lodash/pick';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import get from 'lodash/get';

import { AppDataProps, withAppData } from 'client/contexts/appData';
import FiltersItem from 'client/components/filters-item';
import Icon from 'client/components/icon';
import { FiltersData, FiltersItemThemes, FiltersItemProps } from 'client/types/filters';
import FiltersList from './filters-list';
import FiltersStub from './filters.stub';
import { FilterQueryParams } from './filters-query';

import './filters.css';

interface FilterProps extends FilterQueryParams {
    data: FiltersData;
    isLoading: boolean;
    onChangeFilters: (params: FilterQueryParams) => void;
    maxFilters?: number;
    speakerId?: string;
}

type Props = AppDataProps & FilterProps;

interface State {
    initiated: boolean;
    selectedMainFilterId: string | null;
    selectedFilters: FiltersItemProps[];
    eventName: string | null;
    error?: string
}

const class_ = b.with('filters');

class Filters extends Component<Props, State> {
    static defaultProps = {
        maxFilters: 10,
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const nextState = pick(nextProps, [
            'selectedMainFilterId',
            'selectedFilters',
            'eventName',
        ]);

        if (prevState.initiated) {
            return { ...prevState };
        }

        return {
            ...prevState,
            ...nextState,
            initiated: true,
        };
    }

    state: State = {
        initiated: false,
        selectedMainFilterId: null,
        selectedFilters: [],
        eventName: null,
    };

    private readonly debouncedFiltersChange = noop;

    constructor(props: Props) {
        super(props);

        this.debouncedFiltersChange = debounce(this.onChangeFilters, 200);
    }

    // eslint-disable-next-line complexity
    render() {
        const { data, isLoading, appData: { i18n } } = this.props;
        const {
            selectedFilters,
            selectedMainFilterId,
            eventName,
            error,
        } = this.state;

        if (isLoading) {
            return (
                <FiltersStub />
            );
        }

        if (!data) {
            return null;
        }

        const speaker = this.getSpeakerLabel();

        return (
            <div className={class_()}>
                <div className={class_('search-input-container-mobile')}>
                    <input
                        className={class_('search-input')}
                        type="text"
                        defaultValue={eventName || ''}
                        placeholder={i18n('filters.name')}
                        maxLength={200}
                        onKeyUp={this.onSearchKeyUp}
                    />
                    <Icon type="search" />
                </div>
                <div className={class_('container', { main: true })}>
                    <div className={class_('wrapper')}>
                        {Object.keys(data).map(key => (
                            key !== 'speaker' && <FiltersItem // Todo remove when filter is designed
                                selected={selectedMainFilterId === key}
                                key={key}
                                onClick={this.onToggleMainFilter}
                                theme={FiltersItemThemes.main}
                                label={key}
                                id={key}
                            />
                        ))}
                    </div>
                    <div className={class_('search-input-container')}>
                        <input
                            className={class_('search-input')}
                            type="text"
                            defaultValue={eventName || ''}
                            placeholder={i18n('filters.name')}
                            maxLength={200}
                            onKeyUp={this.onSearchKeyUp}
                        />
                        <Icon type="search" />
                    </div>
                </div>
                {selectedFilters.length || speaker ?
                    <div className={class_('container', { selected: true })}>
                        <div className={class_('selected-filters')}>
                            {Boolean(selectedFilters.length) && selectedFilters.map(filterProps => (
                                <FiltersItem
                                    selected
                                    withFullLabel
                                    key={filterProps.type + filterProps.id!}
                                    onClick={this.onClickToSelectedFilterItem}
                                    {...filterProps}
                                />
                            ))}
                            {speaker &&
                            <FiltersItem
                                id={speaker}
                                label={speaker}
                                selected
                                key={speaker}
                            />}
                        </div>
                    </div> : null}

                {Boolean(selectedMainFilterId) &&
                    <div className={class_('list')}>
                        <div className={class_('container')}>
                            <FiltersList
                                type={selectedMainFilterId!}
                                data={data[selectedMainFilterId!]}
                                selectedFilters={selectedFilters}
                                onClick={this.onClickToFilterItem}
                            />
                        </div>
                    </div>
                }
                {Boolean(error) && (
                    <div className={class_('container', { error: true })}>
                        {error}
                    </div>
                )}
            </div>
        );
    }

    onClickToSelectedFilterItem = (id: string, type: string) => {
        this.removeFilterItem(id, type);
    };

    onClickToFilterItem = (filterProps: FiltersItemProps, type: string) => {
        const { selectedFilters } = this.state;

        // Проверяем, выбранный ли уже фильтр или нет
        const alreadySelected = selectedFilters
            .find(filter => filter.type === type && filter.id === filterProps.id);

        if (alreadySelected) {
            return this.removeFilterItem(filterProps.id!, type);
        }

        this.addFilterItem(filterProps, type);
    };

    private onSearchKeyUp = (event: React.KeyboardEvent<{ value: string }>) => {
        const { value } = event.currentTarget;

        if (value !== this.state.eventName) {
            this.setState({ eventName: value }, this.debouncedFiltersChange);
        }
    };

    private onChangeFilters = () => {
        const { onChangeFilters } = this.props;
        const { selectedFilters, eventName } = this.state;

        onChangeFilters(pickBy({
            selectedFilters,
            eventName,
        }, identity));
    };

    private onToggleMainFilter = (key: string) => {
        const { selectedMainFilterId } = this.state;

        this.setState({
            selectedMainFilterId: selectedMainFilterId === key ? null : key,
        });
    };

    private removeFilterItem = (id: string, type: string) => {
        const { selectedFilters } = this.state;

        this.setState({
            error: undefined,
            selectedFilters: selectedFilters.filter(filter => {
                return !(filter.type === type && filter.id === id);
            }),
        }, this.onChangeFilters);
    };

    private addFilterItem = (filterProps: FiltersItemProps, type: string) => {
        const { maxFilters, appData: { i18n } } = this.props;
        const { selectedFilters } = this.state;

        const filterItem = { type, ...filterProps };

        if (selectedFilters.length + 1 > maxFilters!) {
            this.setState({
                error: i18n('filters.max'),
            });

            return;
        }

        this.setState({
            error: undefined,
            selectedFilters: [...selectedFilters, filterItem],
        }, this.onChangeFilters);
    };

    private getSpeakerLabel() {
        const { speakerId } = this.props;
        const speakers = get(this.props.data, 'speaker[0].children', []);

        if (!speakerId || speakers.length === 0) {
            return '';
        }

        const speaker = speakers.find(({ externalId }: {externalId: number}) => externalId.toString() === speakerId);

        return speaker ? speaker.label : '';
    }
}

export default withAppData(Filters);
